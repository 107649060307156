/*==== Size Veriables ====*/
$size_1:1px;

/*==== Color Veriables ====*/
$color_primary:#029e0f;
$color_secondary:#1882dd;
$color_black:#000;
$color_white:#fff;
$color_gray:#ccc;
$color_danger:#ff4d4f;
$color_blue:#0779fe;
$color_blackDark:#444444;
$color_blackLight:#464646;

$color_border:#D6D4D4;
$color_whHover: #f5f5f5;