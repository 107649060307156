@import 'variables.scss';

.main-layout{ height:100vh; margin:0; padding:0; 
    .ant-layout-sider-trigger{ display:none ; }
}

.side-navigation{ margin:0; padding:0 !important; max-width:260px !important; flex: 0 0 260px !important; background:$color_white !important; box-shadow: 0 1px 7px rgba(0,0,0,.1); overflow:hidden; height:100%;
    .ant-layout-sider-children{ height:100%; display:flex; flex-flow:column; 
        .ant-menu{ flex:1; overflow:auto;
            &.ant-menu::-webkit-scrollbar { width: 4px; }
            &.ant-menu::-webkit-scrollbar-track { box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
            &.ant-menu::-webkit-scrollbar-thumb { background-color: darkgrey; outline:none; }
        }
        .menu-bottom{ padding:0; 
            .primarybtn{ background:transparent; width:100%; border-radius:0; color:$color_blackLight; border:1px solid $color_border; 
                &:hover{ background:transparent !important; color:$color_black !important; }
                &:focus{ background:transparent !important; color:$color_black !important; }
            }
        }
    }
    h1{ font-size:27px; line-height:1.3; color:$color_black; font-weight:700; margin:0; padding:15px; text-align:center; border-bottom:1px solid rgba(0,0,0,.1); }
    .ant-menu-dark{ background:none; }
    .ant-menu-item{ font-size:15px; color:$color_black; border-radius:4px; transition:all 0ms ease-in-out;
        &:hover{ color:$color_black !important; }
        &.ant-menu-item-active{ background:#f1f1f1 !important; color:$color_primary !important;  transition:all 0ms ease-in-out; 
            a{ color:$color_primary; }
        }
        &.ant-menu-item-selected{ background:$color_primary !important; color:$color_white !important; transition:all 0ms ease-in-out;
            a{ color:$color_white; }
        }
       
    }
}

.body-wrapper{ background:#f1f1f1; padding:30px; overflow:auto;  }
// .ant-select-dropdown {
//     width: auto !important;
// }

// .base-modal{
//     .ant-modal-content{ padding:0; 
//         .ant-modal-header{ margin:0; padding:12px 20px; border-bottom:1px solid rgba(0,0,0,.1); 
//             .ant-modal-title{ font-size:18px; line-height:1.3; color:$color_black; font-weight:600; margin:0; padding:0; }
//         }
//         .ant-modal-body{ padding:15px 20px 12px; padding-bottom:38px; position: relative; 
//             .modal-btn{ position:absolute; bottom:0; left:0; right:0; margin:0; padding:0; 
//                 .primarybtn{ width:100%; border-radius: 0 0 6px 6px; }
//             }
//         }
//     }
// }

#payment-outerDiv {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.7);
    padding: 50px;
    display: none;
    box-sizing: border-box;
}

:where(.css-dev-only-do-not-override-17a39f8).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color:#0b851d
}

:where(.css-dev-only-do-not-override-17a39f8).ant-tabs .ant-tabs-tab:hover {
    color:#0b851d
}

:where(.css-dev-only-do-not-override-17a39f8).ant-tabs .ant-tabs-ink-bar {
    background: #0b851d;
}






























