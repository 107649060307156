@import 'variables.scss';

ul, li{ margin:0; padding:0; list-style:none; }


.base-login{ height:100vh; margin:0; padding:0; display:flex; align-items:center; justify-content:center; }
.base-login h1{ font-size:35px; line-height:1.3; color:$color_black; font-weight:700; margin:0; padding:0 0 35px 0; text-align:center; }

.login-block{ width:500px; background:$color_white; box-shadow:0 3px 15px rgba(0,0,0,.2); border:1px solid rgba(0,0,0,.1); margin:0; padding:45px; border-radius:5px; overflow:hidden; }

.form-field{
    .btn-block{ margin-bottom:0 !important; 
        .primarybtn{ width:100%; }
    }
}

.form-box{
    label{ height:auto !important; font-size:14px; font-weight:600; padding-bottom:5px;
        &:before{ display:none !important; }
        &:after{ display:none !important; }
    }
    .ant-input{ height:38px; }
    .ant-input-password{ padding:0; 
        .ant-input{ padding: 4px 11px; }
    }
    .ant-select{
        .ant-select-selector{ height:38px !important; 
            .ant-select-selection-item{ line-height:36px; }
            .ant-select-selection-overflow{
                .ant-select-selection-item{ line-height:22px; }
            }
        }
        &.ant-select-multiple{
            .ant-select-selector{ min-height:38px; height:auto !important; }
        }
    }
    .ant-form-item-row{ display:flex; gap: 16px; flex-direction: row; flex-flow: initial }
    .ant-col {
        flex: initial;
    }
    .ant-form-item-row .ant-col:first-child {
        min-width: 150px;
        max-width: 150px;
        color: #3d5227;
    }
    .ant-form-item-row .ant-col:nth-child(2) {
        flex-grow: 1;
    }
    .ant-radio-wrapper{
        span{ font-weight:400; }
    }
    &.mb-0{
        label{ padding-bottom:0; }
    }
}

.element-body{ margin:0; padding:0; }

.heading-block{ margin:0 0 30px 0; padding:0 0 15px; border-bottom:1px solid $color_border; display:flex; align-items:center; justify-content:space-between;
    h2{ font-size:22px; line-height:1; font-weight:600; color:$color_black; margin:0; padding:0; } 
}

.table-element{
    .ant-table{
        .ant-table-thead{
            .ant-table-cell{ padding-left:12px; padding-right:12px; }
        }
        .ant-table-tbody{
            .ant-table-cell{ padding:8px 12px; }
        }
    }
}

.upform-field{ margin:0; padding:0 0 5px 0; }
.list-field{ margin:0; padding:0; display:flex; align-items:flex-start;
    .lt-block{ flex:1; margin:0; padding:0 20px 0 0; }
    ul{
        li{ font-size:15px; line-height:1; font-weight:400; color:$color_blackDark; margin:0; padding:0; padding-bottom:8px;
            .label-text{ display:inline-block; }
            .value-text{ display:inline-block; font-weight:700; color:$color_black; padding:0 0 0 6px; }
        }
    }
}

.items-element{ margin:0; padding:0; 
    .field-box{ margin:0; padding:0; }
    .top-heading{ margin:0 0 25px 0; padding:10px 0; border-bottom:1px solid $color_border; position: relative;
        h4{ font-size:16px; line-height:1; font-weight:700; color:$color_black; margin:0; padding:0; }
        .addbtn{ position:absolute; top:50%; transform:translateY(-50%); right:0; }
    }
    .list-box{ 
        margin:0; padding:0; 
        ul{
           >  
           li{ 
                margin:0 0 16px; padding:20px; background:$color_white; border-radius:5px; display:flex; align-items:flex-start; box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1); 
                &.full-assigned {
                    background-color: #c5d1b8;
                    border: solid 1px #3d5227;
                }
                &.part-assigned {
                    background-color: #d1cbb8;
                    border: solid 1px #be9113;
                }
                &.not-assigned {
                    background-color:#dec1c1;
                    border: solid 1px #702323;
                }
                &.own-box {
                    background-color: #c4d8ec;
                    border: solid 1px blue;
                }
            }
        }
        .lt-text{ flex:1; margin:0; padding:0 20px 0 0;
            h5{ font-size:14px; line-height:1; font-weight:600; color:$color_black; margin:0; padding:0 0 7px 0; }
            p{ font-size:14px; line-height:1; font-weight:400; color:$color_black; margin:0; padding:0; }
        }
        .rt-form{ width:250px; margin:0; padding:0; }
        ol{ margin:0; padding:0; list-style:none;
            >  li{ font-size:14px; line-height:1; font-weight:400; color:$color_black; margin:0; padding:0 0 8px 0; }
         }
    }
}

.table-feature{ display:flex; align-items:center; margin:0; padding:0; 
    .img-box{ width:75px; height:50px; margin:0 16px 0 0; padding:0; overflow:hidden; background:#ccc;
        img{ width:100%; height:100%; object-fit:cover; }
    }
    .text-block{ flex:1; margin:0; padding:0;
        h4{ font-size:14px; line-height:1.5; color:$color_black; font-weight:400; margin:0; padding:0; }
    }
}

.feature-table{
    .ant-table{
        .ant-table-tbody {
            .ant-table-cell{
                &.ant-table-cell-row-hover{ background:$color_whHover; }
            }
        }
    }
}


.project-add-item {
    cursor: pointer;
    &:hover {
        background: #F4F4F4;
    }
}




















